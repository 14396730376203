<template>
  <PageWrapper :page-data="pageData"
               :brand="brandFromStore">
    <template #header-logo>
      <HeaderLogo />
    </template>
    <template #header-search>
      <HeaderSearch />
    </template>
    <template #header-user-navi>
      <HeaderUserNavi />
    </template>
    <template #cookbook-button-bar>
      <CookbookButtonBar />
    </template>
    <template #footer>
      <PageFooter :footer-data="pageData.footer" />
    </template>
    <template #loading-overlay>
      <LoadingOverlay />
    </template>
  </PageWrapper>
</template>

<script>
import { mapState } from 'pinia'

// these components will be injected into the app via slots
import PageWrapper from '../../components/PageWrapper.vue'
import defaultAppInit from '../../mixins/default-app-init'
import CookbookButtonBar from './theme/Cookbook/ButtonBar.vue'
import PageFooter from './theme/Footer.vue'
import HeaderLogo from './theme/HeaderLogo.vue'
import HeaderSearch from './theme/HeaderSearch.vue'
import HeaderUserNavi from './theme/HeaderUserNavi.vue'
import LoadingOverlay from './theme/LoadingOverlay.vue'
import { usePageStore } from '../../stores/page'

export default {
  components: {
    CookbookButtonBar,
    HeaderLogo,
    HeaderSearch,
    HeaderUserNavi,
    PageFooter,
    LoadingOverlay,
    PageWrapper
  },
  mixins: [defaultAppInit],
  computed: {
    ...mapState(usePageStore, ['pageData', 'brandFromStore'])
  }
}
</script>
