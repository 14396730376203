import ParagraphTypeRecipeSearch from './theme/ParagraphTypeVueComponent/ParagraphTypeRecipeSearch.vue'
import ParagraphTypeLeckerCookbook from './theme/ParagraphTypeVueComponent/ParagraphTypeLeckerCookbook.vue'
import ParagraphTypeLeckerUserProfile from './theme/ParagraphTypeVueComponent/ParagraphTypeLeckerUserProfile.vue'
import ParagraphTypeLeckerRecipeSearchFilterTeaser from './theme/ParagraphTypeVueComponent/ParagraphTypeLeckerRecipeSearchFilterTeaser.vue'

const registerBrandVueComponents = (app) => {
  // vue2 (RSv1) passes the global Vue instance as an argument
  // vue3 (RSv2) passes the app instance as an argument
  app.component('ParagraphTypeRecipeSearch', ParagraphTypeRecipeSearch)
  app.component('ParagraphTypeLeckerCookbook', ParagraphTypeLeckerCookbook)
  app.component('ParagraphTypeLeckerUserProfile', ParagraphTypeLeckerUserProfile)
  app.component('ParagraphTypeLeckerRecipeSearchFilterTeaser', ParagraphTypeLeckerRecipeSearchFilterTeaser)
}

export {
  registerBrandVueComponents
}
