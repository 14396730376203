import { render, staticRenderFns } from "./CollectionAssets.vue?vue&type=template&id=3b52d9a4&"
import script from "./CollectionAssets.vue?vue&type=script&lang=js&"
export * from "./CollectionAssets.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports