<template>
  <div :data-loader="isLoading"
       data-loader-style="container">
    <div v-if="searchErrorMessage?.length"
         class="bx-form__message bx-form__message--error">
      {{ searchErrorMessage }}
    </div>
    <!-- eslint-disable vue/no-unused-refs MIXIN -->
    <div ref="teaserContainer"
         class="bx-teaser-container bx-teaser-container--one-half-width"
         :class="{ 'bx-teaser-container--dialog': dialogAsset }">
      <div class="bx-teaser-container__wrapper">
        <Item v-if="page === 1"
              type="addCollection"
              :badge-mobile="badgeMobile"
              :item="{name: 'Neues Kochbuch'}"
              @collection:item="handleCollectionAction($event)" />
        <Item v-for="(item) in (pageable ? itemsSubset : items.data)"
              :key="`${item?.id}-${item?.name}-${item?.imageIdReplace}`"
              :type="dialogAsset ? 'assetCollectionsDialog' : 'collections'"
              :badge-mobile="badgeMobile"
              :item="item || {}"
              :is-active="dialogAsset && assetCollections.includes(item?.id)"
              @collection:item="handleCollectionAction($event)" />
        <CrudCollectionDialog v-if="showCrudDialog"
                              :mode="crudDialogMode"
                              :collection="selectedCollection"
                              @update:collection:list="updateCollectionList($event)"
                              @close:dialog="closeCrudDialog()" />
      </div>
    </div>
    <PagePagination v-if="numPages > 1"
                    :page-meta="paginationPageMeta" />
    <div v-if="items.data.length && !pageable"
         class="bx-form bx-form__item bx-form__item--centered bx-typo--form">
      <button class="bx-form__button"
              :disabled="isLoading || !endlessLoading"
              :data-loader="isLoading"
              @click.prevent="loadMoreCollections()">
        mehr Kochbücher anzeigen
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'

// import EventBus from '../../../../event-bus/EventBus'
import { useConfigStore } from '../../../../stores/config'
import { useNotifyStore } from '../../../../stores/notify'
import { useSessionStore } from '../../../../stores/session'
import PagePagination from '../../../../components/PagePagination.vue'
import AssetCollections from '../../mixins/AssetCollections.js'
import CollectionsPagination from '../../mixins/CollectionsPagination.js'
import keepTabbingFocus from '../../../../mixins/keepTabbingFocus.js'
import adaDynamicSlotMarker from '../../../../mixins/ada-dynamic-slot-marker.js'
import CrudCollectionDialog from './CrudCollectionDialog.vue'
import Item from './Item.vue'

export default {
  components: {
    PagePagination,
    CrudCollectionDialog,
    Item
  },
  mixins: [AssetCollections, CollectionsPagination, keepTabbingFocus, adaDynamicSlotMarker],
  props: {
    dialogAsset: {
      type: Object,
      default: null
    },
    pageable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedCollection: {},
      showCrudDialog: false,
      crudDialogMode: null,
      badgeMobile: true
    }
  },
  computed: {
    // These store props are used by mixin
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapState(useSessionStore, ['token'])
  },
  watch: {
    'isLoading' () {
      if (!this.isLoading) {
        this.$emit('loading:complete')
        // // disabled asyncContent ad loading solution for further development
        // this.$nextTick(() => {
        //   this.injectMarkerToTeaserContainer()
        //   // trigger Event to notify adLoader
        //   EventBus.$emit('ad-loader:allocation')
        // })
      }
    }
  },
  async mounted () {
    if (this.dialogAsset && !this.assetCollectionsPrefetch) {
      await this.getAssetCollections(this.dialogAsset.assetId)
    }
    this.turnPageAutoScroll = !this.dialogAsset
    this.checkViewport()
    window.addEventListener('resize', this.checkViewport)
  },
  // called in vue2 not called in vue3
  beforeDestroy () {
    this.vue3CompatibleBeforeDestroy()
  },
  // called in vue3 not called in vue2
  beforeUnmount () {
    this.vue3CompatibleBeforeDestroy()
  },
  methods: {
    ...mapActions(useNotifyStore, ['showNotification']),
    vue3CompatibleBeforeDestroy () {
      window.removeEventListener('resize', this.checkViewport)
    },
    async handleCollectionAction (event) {
      if (event.action === 'toggleAsset') {
        this.isLoading = true
        await this.toggleAssetCollection(this.dialogAsset.assetId, event.item.id)
        if (this.assetCollections.includes(event.item.id)) {
          this.items.data[this.items.data.findIndex(obj => obj.id === event.item.id)].imageIdReplace = this.dialogAsset.imageId
        }
        this.isLoading = null // set to null in order to remove the data-loader attribute (vue 3)
      } else if (event.action === 'open') {
        this.$emit('open:collection', { item: event.item })
      } else {
        this.crudDialogMode = event.action
        if (event.action === 'add') {
          this.$emit('change:sort:order:on:add')
        } else {
          this.selectedCollection = event.item
        }
        this.showCrudDialog = true
      }
    },
    async updateCollectionList (event) {
      if (event.action === 'add') {
        this.items.data = [event.data, ...this.items.data]
        this.items.total++
      } else if (event.action === 'rename') {
        this.items.data[this.items.data.findIndex(obj => obj.id === event.data.id)].name = event.data.name
      } else if (event.action === 'delete') {
        this.deleteLocalItem('id', event.data.id)
      }
    },
    closeCrudDialog () {
      this.$emit('crud:dialog:closed')
      this.showCrudDialog = false
      this.selectedCollection = {}
    },
    checkViewport () {
      this.badgeMobile = window.innerWidth < 480
    }
  }
}
</script>
