<template>
  <div class="bx-search__header bx-typo--search-header bx-cookbook__navi">
    <button v-if="view === 'collectionAssets'"
            class="bx-cookbook__back"
            @click="$emit('close:collection')">
      <Icon name="arrow_pager-prev" />
      Zurück
    </button>
    <template v-if="view === 'collections' || view === 'collections-dialog'">
      <form v-if="view === 'collections-dialog'"
            class="bx-form bx-typo--form bx-form--search bx-form--cookbook-search bx-typo--form-search">
        <div class="bx-searchfield">
          <input v-model="searchPhrase"
                 type="search"
                 class="bx-form__input"
                 title="Kochbuch suchen"
                 aria-label="Kochbuch suchen">
          <button aria-label="Suchen"
                  tabindex="-1"
                  @click.prevent="$emit('change:search:phrase', searchPhrase)"
                  @keyup.enter.prevent="$emit('change:search:phrase', searchPhrase)">
            <Icon name="search" />
          </button>
        </div>
      </form>

      <div class="bx-orderselect">
        <div class="bx-orderselect__label bx-typo--orderselect--label">
          <Icon name="sort" />
          <span>Sortieren nach</span>
        </div>
        <div class="bx-orderselect__select">
          <select title="Sortieren nach"
                  aria-label="Sortieren nach"
                  @change="$emit('change:sort:order', $event.target.value)">
            <option v-for="(val, key) in sortOrderList"
                    :key="key"
                    :selected="key === sortOrder"
                    :value="key">
              {{ val }}
            </option>
          </select>
          <Icon name="arrow_toggle" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Icon from '../../../../components/shared/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    view: {
      type: String,
      required: true
    },
    sortOrderList: {
      type: Object,
      required: true
    },
    sortOrder: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      searchPhrase: ''
    }
  }
}
</script>
