<template>
  <Dialog v-if="isClient"
          ref="dialog"
          :title="dialogTitle"
          class="bx-cookbook__dialog"
          data-tc="cookbook-dialog"
          @close="$emit('close:dialog')">
    <template #header>
      <div class="bx-dialog__title">
        <h2>{{ dialogTitle }}</h2>
      </div>
    </template>

    <div v-if="mode === 'delete'"
         class="bx-content__continuous-text bx-typo--content-continuous-text bx-typo--center">
      <p>
        <span v-if="collection.numOfAssets > 0">Dieses Kochbuch enthält <strong>{{ collection.numOfAssets }} Rezept{{ collection.numOfAssets !== 1 ? 'e' : '' }}</strong>.<br></span>
        Möchtest du das Kochbuch <strong>{{ collection.name }}</strong> wirklich löschen?
      </p>
    </div>

    <form class="bx-form bx-form--pur bx-typo--form"
          @submit.prevent="submit()">
      <div v-if="mode !== 'delete'"
           class="bx-form__item">
        <label for="comment"
               class="bx-form__label">
          Name des Kochbuchs
        </label>
        <input id="comment"
               v-model="collectionName"
               data-tc="dialog-input"
               type="text"
               class="bx-form__textarea"
               title="Kochbuchname"
               placeholder="Kochbuchname">
      </div>
      <div class="bx-form__item bx-form__item--centered">
        <button type="button"
                data-tc="dialog-cancel"
                class="bx-form__button bx-form__button--secondary"
                @click.prevent="$emit('close:dialog')">
          Abbrechen
        </button>
        <button type="submit"
                data-tc="dialog-submit"
                class="bx-form__button"
                :disabled="isProcessing || preventSubmit"
                :aria-disabled="isProcessing || preventSubmit"
                :data-loader="isProcessing"
                tabindex="0">
          {{ dialogTitle }}
        </button>
      </div>
    </form>
  </Dialog>
</template>

<script>
import axios from 'axios'
import { mapActions, mapState } from 'pinia'

import { useNotifyStore } from '../../../../stores/notify'
import { useConfigStore } from '../../../../stores/config'
import { useSessionStore } from '../../../../stores/session'
import clientOnly from '../../../../mixins/client-only'
import Dialog from '../../../../components/shared/Dialog.vue'
import { getConfig } from '../../../../stores/utils/helpers'
import keepTabbingFocus from '../../../../mixins/keepTabbingFocus.js'

export default {
  components: {
    Dialog
  },
  mixins: [keepTabbingFocus, clientOnly],
  props: {
    mode: {
      type: String,
      required: true,
      validator: val => ['add', 'rename', 'delete'].includes(val)
    },
    collection: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      collectionName: '',
      latestCollectionName: null,
      isProcessing: null, // set to null in order to remove the data-loader attribute (vue 3)
      preventSubmit: true,
      namingErrorMessage: ''
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapState(useSessionStore, ['token']),
    dialogTitle () {
      if (this.mode === 'delete') {
        return 'Kochbuch löschen'
      } else if (this.mode === 'rename') {
        return 'Kochbuch umbenennen'
      } else {
        return 'Kochbuch anlegen'
      }
    }
  },
  watch: {
    'collectionName' () {
      if (this.mode === 'rename') {
        this.preventSubmit = this.collectionName === this.latestCollectionName
      } else if (this.mode === 'add') {
        this.preventSubmit = this.collectionName.trim().length === 0
      }
      this.keepTabbingFocusInCrudDialog()
    }
  },
  mounted () {
    if (this.mode === 'rename') {
      this.collectionName = this.latestCollectionName = this.collection.name
    }
    if (this.mode === 'delete') {
      this.preventSubmit = false
    }
    this.keepTabbingFocusInCrudDialog()
  },
  methods: {
    ...mapActions(useNotifyStore, ['showNotification']),
    keepTabbingFocusInCrudDialog () {
      this.$nextTick(() => {
        this.removeTabbingEventListeners()
        this.keepTabbingFocus(this.$refs.dialog.$el, ['input, button:not(:disabled)'], 1, () => {
          this.removeTabbingEventListeners()
          this.$emit('close:dialog')
        })
      })
    },
    async submit () {
      this.isProcessing = true
      switch (this.mode) {
        case 'add':
          this.collectionName = this.cleanupCollectionName(this.collectionName)
          await this.addCollection()
          break
        case 'rename':
          this.collectionName = this.cleanupCollectionName(this.collectionName)
          await this.renameCollection()
          break
        case 'delete':
          await this.deleteCollection()
          break
      }
      this.$emit('close:dialog')
      this.isProcessing = null
    },
    async addCollection () {
      try {
        const result = await axios.post(
          `${this.rsConfig.orchestrationServiceUrl}/collections/add`,
          { name: this.collectionName },
          getConfig(this.rsConfig.orchestrationServiceUrl, this.token)
        )
        this.$emit('update:collection:list', {
          action: 'add',
          data: {
            id: result.data.id,
            name: result.data.documentData.name,
            numOfAssets: 0
          }
        })
        this.showNotification({
          delay: 3000,
          title: 'Kochbuch angelegt',
          message: `Das Kochbuch "${result.data.documentData.name}" wurde erfolgreich angelegt. ${this.namingErrorMessage}`
        })
      } catch (e) {
        this.showNotification({
          type: 'error',
          delay: 3000,
          title: 'Fehler beim Anlegen',
          message: 'Beim Anlegen eines neuen Kochbuchs ist leider ein Fehler aufgetreten.'
        })
      }
    },
    async renameCollection () {
      try {
        await axios.post(
          `${this.rsConfig.orchestrationServiceUrl}/collections/${this.collection.id}/rename`,
          { newName: this.collectionName },
          getConfig(this.rsConfig.orchestrationServiceUrl, this.token)
        )
        this.$emit('update:collection:list', {
          action: 'rename',
          data: {
            id: this.collection.id,
            name: this.collectionName
          }
        })
        this.showNotification({
          delay: 3000,
          title: 'Kochbuch umbenannt',
          message: `Das Kochbuch "${this.latestCollectionName}" wurde erfolgreich umbenannt in "${this.collectionName}". ${this.namingErrorMessage}`
        })
      } catch (e) {
        this.showNotification({
          type: 'error',
          delay: 3000,
          title: 'Fehler beim Umbenennen',
          message: 'Beim Umbenennen des Kochbuchs ist leider ein Fehler aufgetreten.'
        })
      }
    },
    async deleteCollection () {
      try {
        await axios.post(
          `${this.rsConfig.orchestrationServiceUrl}/collections/${this.collection.id}/delete`,
          {},
          getConfig(this.rsConfig.orchestrationServiceUrl, this.token)
        )
        this.$emit('update:collection:list', {
          action: 'delete',
          data: {
            id: this.collection.id
          }
        })
        this.showNotification({
          delay: 3000,
          title: 'Kochbuch gelöscht',
          message: `Das Kochbuch "${this.collection.name}" wurde erfolgreich gelöscht.`
        })
      } catch (e) {
        this.showNotification({
          type: 'error',
          delay: 3000,
          title: 'Fehler beim Löschen',
          message: 'Beim Löschen des Kochbuchs ist leider ein Fehler aufgetreten.'
        })
      }
    },
    cleanupCollectionName (collectionName) {
      const cleanCollectionName = collectionName.replace(/([^a-z0-9ßäöü+ -])*/gi, '')
      if (cleanCollectionName !== collectionName) {
        this.namingErrorMessage = 'Es wurden unzulässige Zeichen aus dem Kochbuch Namen entfernt!'
      }
      return cleanCollectionName.replaceAll('+', ' ')
    }
  }
}
</script>
