var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bx-search bx-typo--search"},[_c('ParagraphTypeSearch',{ref:"paragraphTypeSearchRef",attrs:{"brand":"lecker","title":"Suche","aria-label":"Suche","infinite-scroll":false,"initial-search":false,"filters-open":_vm.filtersOpen,"label-search-button":false,"teaser-group-type":"bx-teaser-container--recipe","label":"Rezepte","search":{
      fuzzy: true,
      'searchFieldPaths': [
        'documentData.headline^2',
        'documentData.paragraphs.recipe.ingredientGroups.ingredients.ingredientName'
      ],
      'assetType': 'article',
      'articleType': 'recipe',
      'filters':[
        {
          id: 'categoryfilters',
          groupName: 'categoryfilters',
          label: 'Kategorie',
          class: 'bx-filters bx-filters--categoryfilters bx-typo--filters',
          items: [{ type: 'radiogroup',
                    name: 'category',
                    fieldPath: 'documentData.paragraphs.recipe.category',
                    class: 'bx-filters__category',
                    id: 'category',
                    valueChoices: _vm.categoryItems
                  },
                  { type: 'checkbox',
                    name: 'magazinerecipe',
                    fieldPath: 'publicationMeta.usages.usage.brand',
                    class: 'bx-filters__magazine-recipe',
                    id: 'magazinerecipe',
                    label: 'Rezept aus LECKER-Heft',
                    value: 'LECKER'
                  }]
        },
        {
          id: 'morefilters',
          groupName: 'morefilters',
          class: 'bx-filters bx-filters--morefilters bx-typo--filters',
          items: [{ type: 'togglebubblechooser',
                    name: 'diet',
                    fieldPath: 'documentData.paragraphs.recipe.dietaryConsiderations',
                    class: 'bx-filters__diet',
                    id: 'diet',
                    label: 'Ernährungsart',
                    valueChoices: _vm.dietaryItems,
                    valueType: 'multichoice'
                  },
                  {type: 'range',
                   name: 'cookingtime',
                   fieldPath: 'documentData.paragraphs.recipe.cookingTime.cookingTotal',
                   class: 'bx-range__input',
                   id: 'cookingtime',
                   label:'Zubereitungszeit',
                   labelPostfix: '(in Min.)',
                   showRangeValue: true,
                   default: 125, min: 0, max: 125, stepSize: 5, stepUnit:'Minuten'
                  },
                  {type: 'range',
                   name: 'calories',
                   fieldPath: 'documentData.paragraphs.recipe.nutritionFacts.calories',
                   class: 'bx-range__input',
                   id: 'calories',
                   label:'Kalorien',
                   labelPostfix: '(in kcal)',
                   showRangeValue: true,
                   default: 1050, min: 0, max: 1050, stepSize: 50, stepUnit:'kcal'
                  }]
        }
      ],
      'orderSelect':{
        'label': 'Sortieren nach',
        'icon': 'sort',
        'options':[
          {
            id: 'bestresults',
            label: 'Beste Ergebnisse',
            order: 'desc',
            value: '',
            orderFieldPath: ''
          },
          {
            id: 'reciperating',
            label: 'Bewertung',
            order: 'desc',
            value: 'Bewertung',
            orderFieldPath: 'documentMeta.rating.value'
          },
          {
            id: 'publicationdate',
            label: 'Aktualität',
            order: 'desc',
            value: 'Aktualität',
            orderFieldPath: 'documentMeta.displayDate'
          }]
      }
    }},on:{"search-phrase-changed":_vm.searchPhraseChanged},scopedSlots:_vm._u([{key:"searchandfiltertoggle",fn:function(){return [_c('div',{staticClass:"bx-filtertoggle",attrs:{"tabindex":"0","role":"button","aria-label":_vm.ariaLabelFilters,"aria-expanded":_vm.filtersOpen},on:{"click":function($event){$event.preventDefault();return _vm.toggleFilters.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.toggleFilters.apply(null, arguments)}}},[_c('Icon',{class:_vm.filtersOpen ? 'icon--active' : 'icon--inactive',attrs:{"name":"filters"}}),_c('span',[_vm._v(_vm._s(_vm.labelFilterToggle()))])],1)]},proxy:true},{key:"filtertogglebottom",fn:function(){return [_c('div',{attrs:{"tabindex":"0","role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleFilters.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.toggleFilters.apply(null, arguments)}}},[_c('Icon',{attrs:{"name":"arrow_toggle"}}),_c('span',[_vm._v("Filter schliessen")])],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }